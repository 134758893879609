import docsearch from "@docsearch/js";

if (window.fdocs.algolia) {
	const search = document.getElementById("docsearch");

	if (search) {
		docsearch({
			container: "#docsearch",
			...window.fdocs.algolia,
		});

		search.classList.remove("Header-search--noscript");
	}
}
